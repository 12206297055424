"use client";

import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider from "react-infinite-logo-slider";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { animated, useSpring } from "@react-spring/web";
import { Typography } from "antd";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import es from "@/lang/es";

const PublicSwitchTextCarousel = () => {
  const { isMobile } = useIsMobile();
  const { pageType } = usePublicSwitchPage();

  const { backgroundFrom, backgroundTo } = useSpring({
    backgroundFrom:
      pageType === "userStudent"
        ? "linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%)"
        : "linear-gradient(270deg, rgba(17,25,40,0) 0%, rgba(17,25,40,0.9) 50%, rgba(17,25,40,1) 100%)",
    backgroundTo:
      pageType === "userStudent"
        ? "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%)"
        : "linear-gradient(90deg, rgba(17,25,40,0) 0%, rgba(17,25,40,0.9) 50%, rgba(17,25,40,1) 100%)",
  });

  return (
    <>
      <div
        style={{
          maxWidth: 1100,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "0 16px",
        }}
      >
        <div style={{ width: "100%" }}>
          <Typography.Text
            style={{
              fontWeight: 400,
              fontSize: 20,
              color: pageType === "userStudent" ? "#111928" : "#ffffff",
            }}
          >
            {es.switch.textCarousel.title}
          </Typography.Text>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          width: "90%",
        }}
      >
        <animated.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "20%",
            height: "100%",
            zIndex: 49,
            background: backgroundFrom,
          }}
        />
        <animated.div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "20%",
            height: "100%",
            zIndex: 49,
            background: backgroundTo,
          }}
        />

        {isMobile ? (
          <Slider width="1700px" duration={40} pauseOnHover blurBorders={false}>
            <Slider.Slide>
              <Typography.Title
                level={3}
                style={{
                  fontWeight: 500,
                  fontSize: 40,
                  color: pageType === "userStudent" ? "#111928" : "#ffffff",
                }}
              >
                {es.switch.textCarousel.subTitle}
              </Typography.Title>
            </Slider.Slide>
            <Slider.Slide>
              <Typography.Title
                level={3}
                style={{
                  fontWeight: 500,
                  fontSize: 40,
                  color: pageType === "userStudent" ? "#111928" : "#ffffff",
                }}
              >
                {es.switch.textCarousel.subTitle}
              </Typography.Title>
            </Slider.Slide>
          </Slider>
        ) : (
          <Slider width="3100px" duration={40} pauseOnHover blurBorders={false}>
            <Slider.Slide>
              <Typography.Title
                level={3}
                style={{
                  fontWeight: 500,
                  fontSize: 70,
                  color: pageType === "userStudent" ? "#111928" : "#ffffff",
                }}
              >
                {es.switch.textCarousel.subTitle}
              </Typography.Title>
            </Slider.Slide>
            <Slider.Slide>
              <Typography.Title
                level={3}
                style={{
                  fontWeight: 500,
                  fontSize: 70,
                  color: pageType === "userStudent" ? "#111928" : "#ffffff",
                }}
              >
                {es.switch.textCarousel.subTitle}
              </Typography.Title>
            </Slider.Slide>
          </Slider>
        )}
      </div>
    </>
  );
};

export default PublicSwitchTextCarousel;
