import { assets } from "@koble/assets/assets";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Typography } from "antd";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import generatePublicLinearGradient from "@/common/generatePublicLinearGradient";

const PublicSwitchTestimony = ({
  name,
  position,
  text,
}: {
  name: string;
  position: string;
  text: string;
}) => {
  const { isMobile } = useIsMobile();
  const { pageType } = usePublicSwitchPage();

  return (
    <div
      style={{
        background:
          pageType === "userStudent"
            ? generatePublicLinearGradient({
                colorType: "blue",
                angle: 90,
              })
            : generatePublicLinearGradient({
                colorType: "orange",
                angle: 90,
              }),
        textAlign: "center",
        padding: 40,
        paddingLeft: isMobile ? 20 : 50,
        paddingRight: isMobile ? 20 : 50,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 10,
      }}
    >
      <img
        src={
          pageType === "userStudent"
            ? assets.PUBLIC_USER_STUDENT_TESTIMONY_AVATAR
            : assets.PUBLIC_USER_RECRUITER_TESTIMONY_AVATAR
        }
        style={{ width: 328 / 2 }}
        alt={name}
      />

      <Typography.Text
        style={{
          color: "white",
          fontSize: 24,
          lineHeight: "30px",
          fontWeight: 600,
          marginTop: 20,
        }}
      >
        {name}
      </Typography.Text>
      {isMobile ? (
        <Typography.Text
          style={{
            color: "white",
            fontSize: 16,
            lineHeight: "24px",
            fontWeight: 400,
            marginTop: 5,
          }}
        >
          {position}
        </Typography.Text>
      ) : (
        <Typography.Text
          style={{
            color: "white",
            fontSize: 18,
            lineHeight: "26px",
            fontWeight: 400,
            marginTop: 5,
          }}
        >
          {position}
        </Typography.Text>
      )}
      {isMobile ? (
        <Typography.Text
          style={{
            color: "white",
            fontSize: 18,
            marginTop: 30,
            lineHeight: "30px",
            fontWeight: 600,
          }}
        >
          {text}
        </Typography.Text>
      ) : (
        <Typography.Text
          style={{
            color: "white",
            fontSize: 24,
            marginTop: 30,
            lineHeight: "30px",
            fontWeight: 600,
          }}
        >
          {text}
        </Typography.Text>
      )}
    </div>
  );
};

export default PublicSwitchTestimony;
