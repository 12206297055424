import { ReactNode } from "react";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Col, Divider, Row, Typography } from "antd";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import AnimateOnScroll from "@/common/AnimateOnScroll";

const Text = ({
  title,
  subTitle,
  description,
  descriptionNode,
  subTitleFontSize = 30,
}: {
  title: string;
  subTitle: string;
  description: string;
  descriptionNode?: ReactNode;
  subTitleFontSize?: number;
}) => {
  const { pageType } = usePublicSwitchPage();
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography.Text
        style={{
          color: pageType === "userStudent" ? "#111928" : "#ffffff",
          fontWeight: 600,
          fontSize: 12,
        }}
      >
        {title.toUpperCase()}
      </Typography.Text>
      <Divider
        style={{
          marginTop: 5,
          marginBottom: 14,
          backgroundColor: pageType === "userStudent" ? "#E5E7EB" : "#374151",
        }}
      />
      <Typography.Title
        level={3}
        style={{
          color: pageType === "userStudent" ? "#111928" : "#ffffff",
          fontWeight: 600,
          fontSize: subTitleFontSize,
          margin: 0,
          marginBottom: 14,
        }}
      >
        {subTitle}
      </Typography.Title>
      {descriptionNode || null}
      <Typography.Text
        style={{
          fontWeight: 400,
          color: pageType === "userStudent" ? "#111928" : "#ffffff",
          fontSize: 16,
          lineHeight: "24px",
          marginTop: 5,
        }}
      >
        {description}
      </Typography.Text>
    </div>
  );
};

const PublicSwitchTextAndImageSection = ({
  title,
  subTitle,
  description,
  descriptionNode,
  side,
  imgSrc,
  imgAlt,
  imageOnBottom,
}: {
  title: string;
  subTitle: string;
  description: string;
  descriptionNode?: ReactNode;
  side: "left" | "right";
  imgSrc: string;
  imgAlt: string;
  imageOnBottom?: boolean;
}) => {
  const { isMobile } = useIsMobile();

  if (isMobile) {
    return (
      <Row>
        <Col xs={{ span: 24, order: imageOnBottom ? 2 : 1 }}>
          <AnimateOnScroll direction="bottomToTop">
            <img src={imgSrc} style={{ width: "100%" }} alt={imgAlt} />
          </AnimateOnScroll>
        </Col>
        <Col xs={24} order={imageOnBottom ? 1 : 2}>
          <AnimateOnScroll
            direction="bottomToTop"
            style={{
              paddingRight: 8,
              paddingLeft: 8,
              marginTop: 10,
              marginBottom: imageOnBottom ? 20 : 0,
            }}
          >
            <Text
              title={title}
              subTitle={subTitle}
              description={description}
              descriptionNode={descriptionNode}
              subTitleFontSize={28}
            />
          </AnimateOnScroll>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col
        xs={{
          span: 12,
          order: side === "left" ? 1 : 2,
        }}
      >
        <AnimateOnScroll
          reappear
          style={{
            height: "100%",
            paddingRight: side === "left" ? 40 : 0,
            paddingLeft: side === "right" ? 40 : 0,
          }}
          direction={side === "left" ? "leftToRight" : "rightToLeft"}
          threshold={0.2}
        >
          <Text
            title={title}
            subTitle={subTitle}
            description={description}
            descriptionNode={descriptionNode}
          />
        </AnimateOnScroll>
      </Col>
      <Col
        xs={{
          span: 12,
          order: side === "left" ? 2 : 1,
        }}
      >
        <AnimateOnScroll
          direction={side === "left" ? "rightToLeft" : "leftToRight"}
          reappear
          threshold={0.2}
        >
          <img src={imgSrc} style={{ width: "100%" }} alt={imgAlt} />
        </AnimateOnScroll>
      </Col>
    </Row>
  );
};

export default PublicSwitchTextAndImageSection;
