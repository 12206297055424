import { assets } from "@koble/assets/assets";
import ssoRouteManager from "@koble/config/src/ssoRouteManager";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Col, Row, Typography } from "antd";

import PublicSwitchPrimaryButton from "@/app/public-switch/common/PublicSwitchHeader/PublicSwitchPrimaryButton";
import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import generatePublicLinearGradient from "@/common/generatePublicLinearGradient";
import es from "@/lang/es";

const PublicSwitchPreFooter = () => {
  const { pageType } = usePublicSwitchPage();
  const { isMobile } = useIsMobile();

  return (
    <div
      style={{
        background:
          pageType === "userStudent"
            ? generatePublicLinearGradient({
                colorType: "orange",
                angle: 90,
              })
            : generatePublicLinearGradient({
                colorType: "blue",
                angle: 90,
              }),
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Row
        style={{
          maxWidth: 1300,
          width: "100%",
          padding: "0 16px",
        }}
        gutter={[16, 16]}
      >
        <Col
          xs={24}
          md={12}
          style={{
            display: "flex",
            height: "auto",
            flexDirection: "column",
            justifyContent: "center",
            ...(isMobile && {
              marginTop: 70,
            }),
          }}
        >
          <Typography.Text
            style={{
              color: "#FFFFFF",
              fontWeight: 600,
              fontSize: 14,
            }}
          >
            {pageType === "userStudent"
              ? es.switch.preFooter.userStudent.title
              : es.switch.preFooter.userRecruiter.title}
          </Typography.Text>
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Typography.Title
              level={2}
              style={{
                color: "#FFFFFF",
                fontWeight: 600,
                fontSize: isMobile ? 28 : 40,
                margin: 0,
              }}
            >
              {pageType === "userStudent"
                ? es.switch.preFooter.userStudent.subTitle
                : es.switch.preFooter.userRecruiter.subTitle}
            </Typography.Title>
          </div>
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Typography.Text
              style={{
                color: "#FFFFFF",
                fontSize: isMobile ? 16 : 18,
                fontWeight: 400,
              }}
            >
              {pageType === "userStudent"
                ? es.switch.preFooter.userStudent.description
                : es.switch.preFooter.userRecruiter.description}
            </Typography.Text>
          </div>
          <div>
            <a
              href={
                pageType === "userStudent"
                  ? ssoRouteManager.signUpUserStudent(true)
                  : ssoRouteManager.signUpUserRecruiter(true)
              }
            >
              <PublicSwitchPrimaryButton
                label={
                  pageType === "userStudent"
                    ? es.switch.preFooter.userStudent.button
                    : es.switch.preFooter.userRecruiter.button
                }
                size="large"
                color="white"
                background={
                  pageType === "userStudent"
                    ? generatePublicLinearGradient({
                        colorType: "blue",
                        angle: 90,
                      })
                    : generatePublicLinearGradient({
                        colorType: "orange",
                        angle: 90,
                      })
                }
                style={{
                  border: "none",
                  marginTop: 24,
                }}
              />
            </a>
          </div>
        </Col>
        {!isMobile && (
          <Col
            md={{ span: 10, offset: 1 }}
            lg={{ span: 10, offset: 2 }}
            xs={24}
            style={{
              height: 500,
              zIndex: 9,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <img
              alt="shield"
              src={assets.KOBLE_LOGO_WATERMARK}
              style={{
                marginTop: 10,
              }}
            />
          </Col>
        )}
      </Row>
      {isMobile && (
        <div
          style={{
            height: 300,
            width: "100vw",
            marginTop: 20,
            overflow: "hidden",
          }}
        >
          <img
            alt="shield"
            src={assets.KOBLE_LOGO_WATERMARK}
            style={{
              width: 450,
              height: 450,
              overflow: "hidden",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PublicSwitchPreFooter;
