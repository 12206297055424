import React, { useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Slider from "react-infinite-logo-slider";
import { animated, useSpring } from "@react-spring/web";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";
import AnimateOnScroll from "@/common/AnimateOnScroll";
import {
  AspiriaLogo,
  BansiLogo,
  CortevaLogo,
  DaltonLogo,
  IcmLogo,
  InjcLogo,
  KingalaLogo,
  LumstonLogo,
  MapfreLogo,
  MatchmakersLogo,
  PlusvalinkLogo,
  PowerdepotLogo,
  SevillasolLogo,
  ToyotaLogo,
} from "@/common/logos/logos";

const Img = ({ logo, height }: { logo: React.ReactNode; height?: number }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        transform: hovered ? "scale(1.1)" : "scale(1)",
        transition: "transform 0.2s",
        cursor: "pointer",
        height: 70,
        alignItems: "center",
        flexDirection: "column",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div style={{ height: hovered ? (height || 40) * 1.07 : height || 40 }}>
        {logo}
      </div>
    </div>
  );
};
const PublicSwitchLogoCloud = () => {
  const { pageType } = usePublicSwitchPage();
  const color = pageType === "userStudent" ? "#111928" : "white";

  const { backgroundFrom, backgroundTo } = useSpring({
    backgroundFrom:
      pageType === "userStudent"
        ? "linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%)"
        : "linear-gradient(270deg, rgba(17,25,40,0) 0%, rgba(17,25,40,0.9) 50%, rgba(17,25,40,1) 100%)",
    backgroundTo:
      pageType === "userStudent"
        ? "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,1) 100%)"
        : "linear-gradient(90deg, rgba(17,25,40,0) 0%, rgba(17,25,40,0.9) 50%, rgba(17,25,40,1) 100%)",
  });

  return (
    <AnimateOnScroll
      style={{
        height: 80,
        position: "relative",
        width: "100%",
      }}
    >
      <animated.div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "20%",
          height: "100%",
          zIndex: 49,
          background: backgroundFrom,
        }}
      />
      <animated.div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "20%",
          height: "100%",
          zIndex: 49,
          background: backgroundTo,
        }}
      />
      <Slider width="180px" duration={50} pauseOnHover blurBorders={false}>
        <Slider.Slide>
          <Img logo={<AspiriaLogo color={color} />} height={30} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<BansiLogo color={color} />} height={30} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<CortevaLogo color={color} />} height={25} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<DaltonLogo color={color} />} height={25} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<IcmLogo color={color} />} height={25} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<KingalaLogo color={color} />} height={30} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<LumstonLogo color={color} />} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<MapfreLogo color={color} />} height={18} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<InjcLogo color={color} />} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<MatchmakersLogo color={color} />} height={30} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<PlusvalinkLogo color={color} />} height={15} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<PowerdepotLogo color={color} />} height={16} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<SevillasolLogo color={color} />} height={40} />
        </Slider.Slide>
        <Slider.Slide>
          <Img logo={<ToyotaLogo color={color} />} height={20} />
        </Slider.Slide>
      </Slider>
    </AnimateOnScroll>
  );
};

export default PublicSwitchLogoCloud;
