import React from "react";
import { PublicKpi } from "@koble/api/public/getPublicKpis";
import { useElementOnScreen } from "@koble/hooks";
import { animated, useSpring } from "@react-spring/web";
import { Col, ColProps, Row, Typography } from "antd";

import {
  PUBLIC_BLUE_GRADIENT_FROM_COLOR,
  PUBLIC_ORANGE_GRADIENT_FROM_COLOR,
} from "@/common/publicColors";

const { Text } = Typography;
const PublicKpis = ({ kpis }: { kpis: PublicKpi }) => {
  const [containerRef, isVisible] = useElementOnScreen({
    threshold: 0,
    reappear: false,
  });

  const {
    totalBusinesses,
    totalMatches,
    totalUserStudents,
    totalUniversities,
  } = useSpring({
    totalBusinesses: isVisible ? kpis.totalBusinesses : 0,
    totalMatches: isVisible ? kpis.totalMatches : 0,
    totalUserStudents: isVisible ? kpis.totalUserStudents : 0,
    totalUniversities: isVisible ? kpis.totalUniversities : 0,
    config: { duration: 1000 },
  });

  const CustomCol = ({
    children,
    ...props
  }: { children: React.ReactNode } & ColProps) => (
    <Col
      xs={12}
      md={6}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      {...props}
    >
      {children}
    </Col>
  );

  return (
    <Row ref={containerRef} gutter={[32, 32]}>
      <CustomCol>
        <Text
          style={{
            color: PUBLIC_ORANGE_GRADIENT_FROM_COLOR,
            fontSize: 48,
            fontWeight: 600,
          }}
        >
          <animated.div>
            {totalUniversities.to((n) => `+${n.toFixed(0)}`)}
          </animated.div>
        </Text>
        <Text
          style={{
            color: "white",
            fontSize: 18,
            fontWeight: 400,
          }}
        >
          Universidades
        </Text>
      </CustomCol>
      <CustomCol>
        <Text
          style={{
            color: "white",
            fontSize: 48,
            fontWeight: 600,
          }}
        >
          <animated.div>
            {totalBusinesses.to((n) => `+${n.toFixed(0)}`)}
          </animated.div>
        </Text>
        <Text
          style={{
            color: "white",
            fontSize: 18,
            fontWeight: 400,
          }}
        >
          Empresas
        </Text>
      </CustomCol>
      <CustomCol>
        <Text
          style={{
            color: PUBLIC_BLUE_GRADIENT_FROM_COLOR,
            fontSize: 48,
            fontWeight: 600,
          }}
        >
          <animated.div>
            {totalUserStudents.to((n) => `+${n.toFixed(0)}`)}
          </animated.div>
        </Text>
        <Text
          style={{
            color: "white",
            fontSize: 18,
            fontWeight: 400,
          }}
        >
          Estudiantes
        </Text>
      </CustomCol>
      <CustomCol>
        <Text
          style={{
            color: "white",
            fontSize: 48,
            fontWeight: 600,
          }}
        >
          <animated.div>
            {totalMatches.to((n) => `+${n.toFixed(0)}`)}
          </animated.div>
        </Text>
        <Text
          style={{
            color: "white",
            fontSize: 18,
            fontWeight: 400,
          }}
        >
          Matches
        </Text>
      </CustomCol>
    </Row>
  );
};

export default PublicKpis;
