import React from "react";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Typography } from "antd";

import { usePublicSwitchPage } from "@/app/public-switch/PublicSwitchPageHook";

const PublicSwitchSectionTitle = ({
  title,
  subTitle,
  style,
  subTitleFontSize,
  subTitleLineHeight,
}: {
  title: string;
  subTitle: string;
  style?: React.CSSProperties;
  subTitleFontSize?: number;
  subTitleLineHeight?: string;
}) => {
  const { isMobile } = useIsMobile();
  const { pageType } = usePublicSwitchPage();

  return (
    <div
      style={{
        width: "100%",
        ...style,
      }}
    >
      <Typography.Title
        level={3}
        style={{
          color: pageType === "userStudent" ? "#111928" : "#ffffff",
          fontWeight: 600,
          fontSize: 12,
          lineHeight: "20px",
          margin: 0,
        }}
      >
        {title}
      </Typography.Title>
      <Typography.Title
        level={4}
        style={{
          color: pageType === "userStudent" ? "#111928" : "#ffffff",
          fontWeight: 600,
          fontSize: subTitleFontSize || (isMobile ? 30 : 40),
          margin: 0,
          marginTop: 8,
          lineHeight: subTitleLineHeight || (isMobile ? "38px" : "54px"),
        }}
      >
        {subTitle}
      </Typography.Title>
    </div>
  );
};

export default PublicSwitchSectionTitle;
