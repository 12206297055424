import PublicKpis from "@/app/public-switch/common/PublicKpis";
import PublicSwitchFooter from "@/app/public-switch/common/PublicSwitchFooter";
import PublicSwitchLogoCloud from "@/app/public-switch/common/PublicSwitchLogoCloud";
import PublicSwitchPreFooter from "@/app/public-switch/common/PublicSwitchPreFooter";
import PublicSwitchSectionTitle from "@/app/public-switch/common/PublicSwitchSectionTitle";
import PublicSwitchTestimony from "@/app/public-switch/common/PublicSwitchTestimony";
import PublicSwitchTextAndImageSection from "@/app/public-switch/common/PublicSwitchTextAndImageSection";
import PublicSwitchTextCarousel from "@/app/public-switch/common/PublicSwitchTextCarousel";

export {
  PublicKpis,
  PublicSwitchFooter,
  PublicSwitchLogoCloud,
  PublicSwitchPreFooter,
  PublicSwitchSectionTitle,
  PublicSwitchTestimony,
  PublicSwitchTextAndImageSection,
  PublicSwitchTextCarousel,
};
